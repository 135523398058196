import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { PageSection, PageTextRectangle, PageBanner } from '../components'
import { StaticImage } from 'gatsby-plugin-image'
import { savanorystePage } from '../assets/texts/savanoryste-page'
import { VolunteerCard } from '../components/VolunteerCard'

const volunteerImage1 = () => (
  <StaticImage
    className="volunteer-image"
    src="../assets/images/viktoras.jpg"
    alt="volunteerImage1"
    // width={400}
  />
)
const volunteerImage2 = () => (
  <StaticImage
    className="volunteer-image"
    src="../assets/images/monika.jpg"
    alt="volunteerImage3"
    // width={400}
  />
)
const volunteerImage3 = () => (
  <StaticImage
    className="volunteer-image"
    src="../assets/images/gerda.jpg"
    alt="volunteerImage3"
    // width={400}
  />
)

const volunteers = [
  {
    name: 'SAVANORIS VIKTORAS',
    description: 'Prie komandos prisijungiau visai neseniai, vis apie tai galvodavau, tačiau niekaip nedrįsdavau. Kai pirmą kartą nuvykau į vieną iš išvykų, labai naudingai praleidau laiką bei supratau, kad tai nuostabi patirtis. Nuo tuomet pradėjau į jas vykti dažniau. Dabar visai nesigailiu ir džiaugiuosi, būdamas komandos dalimi. Taip pat didžiuojuosi, galėdamas prisidėti prie savo krašto pažinimo.',
    ImageComponent: volunteerImage1,
  },
  {
    name: 'SAVANORĖ MONIKA',
    description: 'Mano savanorystės kelias prasidėjo 2019 m. Kraštotyra susidomėjau devintoje klasėje. Labai apsidžiaugiau, kai sužinojau, jog šiuo savo pomėgiu galiu užsiimti „Krašto pažinimo ir tyrimų centre“. Į jų veiklą kaip savanorė įsiliejau labai greitai. Man buvo suteiktos visos galimybės gilinti savo krašto bei istorijos žinias per įvairias ekspedicijas, stovyklas, kraštotyrinę veiklą. Ši veikla tapo neatsiejama mano gyvenimo dalimi. Savanorystė man padėjo išsiugdyti bendravimo įgūdžius, įgijau savarankiškumo, ėmiau labiau pasitikėti savimi. \n' +
      'Džiaugiuosi, kad savanoriaudama atradau save 😊.',
    ImageComponent: volunteerImage2,
  },
  {
    name: 'SAVANORĖ GERDA',
    description: 'Į kraštotyrą-archeologiją įsitraukiau 2018 metais. Vos pirmą kartą nuvykusi į ekspediciją, nenorėjau iš jos išvykti, tai buvo nauja ir be galo įdomi patirtis. Džiaugiuosi turėjusi galimybę prisidėti prie šaunios komandos, kuri visada linksma ir pasiruošusi iššūkiams. Geriausi mano prisiminimai su ja yra naktys, kurias mes praleidome kartu, žaisdami įvairius stalo žaidimus bei kalbėdami, sėdint prie laužo.',
    ImageComponent: volunteerImage3,
  }
]

const Savanoryst = () => (
  <Layout>
    <Helmet>
      <title>Krasto pazinimas - savanoryste</title>
      <meta name="description" content="savanoryste" />
    </Helmet>
    
  <PageBanner title='Savanorystė'>
    <StaticImage
      className="page-banner-image"
      src="../assets/images/savanoryste_banner.jpg"
      alt="savanoryste"
      layout="fullWidth"
    />
  </PageBanner>
  
  <PageSection className="section-4 content-page">
    <PageTextRectangle
      className='white-text-background'
      text={savanorystePage.paragraph}
    />
  </PageSection>
  
  {/*<div className="volunteer-section">*/}
  <PageSection className="section-4 content-page volunteer-section">
    {volunteers.map(({ description, ImageComponent, name }) => (
      <VolunteerCard key={name} name={name} description={description}>
        <ImageComponent />
      </VolunteerCard>
    ))}
  </PageSection>
</Layout>
)

export default Savanoryst
