import React from 'react'

export const VolunteerCard = ({ children, description, name }) => (
  <div className="volunteer-card">
    <div className="volunteer-image">
      {children}
    </div>
    
    <div className="volunteer-info-wrapper">
      <div className="volunteer-name">{name}</div>
      <div className="volunteer-description">{description}</div>
    </div>
  </div>
)
